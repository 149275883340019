<template>
  <a-config-provider :locale="zh_CN">
    <router-view />
  </a-config-provider>
</template>

<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import { defineComponent } from "vue";
export default defineComponent({
  setup() {
    return {
      zh_CN,
    };
  },
});
</script>

<style lang="scss">
@import "./scss/base.scss";
</style>
