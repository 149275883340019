import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      component: () =>
        import(/* webpackChunkName: "login" */ "../page/login.vue"),
      name: "登录",
    },
    {
      path: "/wxlogin",
      component: () =>
        import(/* webpackChunkName: "wxlogin" */ "../page/wxlogin.vue"),
      name: "微信登录",
    },
    {
      path: "/platform",
      component: () =>
        import(/* webpackChunkName: "platform" */ "../page/platform.vue"),
      name: "系统",
      children: [
        {
          path: "/platform",
          redirect: "/index",
        },
        {
          path: "/index",
          component: () =>
            import(/* webpackChunkName: "index" */ "../page/index.vue"),
          name: "首页",
        },
        {
          path: "/generation",
          component: () =>
            import(
              /* webpackChunkName: "generation" */ "../page/generation.vue"
            ),
          name: "辈分管理",
        },
        {
          path: "/member",
          component: () =>
            import(/* webpackChunkName: "member" */ "../page/member.vue"),
          name: "成员管理",
        },
        {
          path: "/relation",
          component: () =>
            import(/* webpackChunkName: "relation" */ "../page/relation.vue"),
          name: "成员关系",
        },
        {
          path: "/famous",
          component: () =>
            import(/* webpackChunkName: "famous" */ "../page/famous.vue"),
          name: "名人轶事",
        },
        {
          path: "/elegant",
          component: () =>
            import(/* webpackChunkName: "elegant" */ "../page/elegant.vue"),
          name: "家族风采",
          children: [
            {
              path: "/elegant_ancestral",
              component: () =>
                import(
                  /* webpackChunkName: "ancestral" */ "../page/ancestral.vue"
                ),
            },
            {
              path: "/elegant_album",
              component: () =>
                import(
                  /* webpackChunkName: "album" */ "../page/photoAlbum.vue"
                ),
            },
            {
              path: "/elegant_albumDetail",
              component: () =>
                import(
                  /* webpackChunkName: "albumDetail" */ "../page/albumDetail.vue"
                ),
            },
            {
              path: "/elegant_personImg",
              component: () =>
                import(
                  /* webpackChunkName: "personImg" */ "../page/personImg.vue"
                ),
            },
            {
              path: "/elegant_personDetail",
              component: () =>
                import(
                  /* webpackChunkName: "personDetail" */ "../page/personDetail.vue"
                ),
            },
          ],
        },
        {
          path: "/instantbook",
          component: () =>
            import(
              /* webpackChunkName: "instantbook" */ "../page/instantbook.vue"
            ),
          name: "大事记",
        },
        {
          path: "/system",
          component: () =>
            import(/* webpackChunkName: "system" */ "../page/system.vue"),
          name: "系统管理",
          children: [
            {
              path: "/menu",
              component: () =>
                import(/* webpackChunkName: "menu" */ "../page/menu.vue"),
            },
            {
              path: "/role",
              component: () =>
                import(/* webpackChunkName: "role" */ "../page/role.vue"),
            },
            {
              path: "/manager",
              component: () =>
                import(/* webpackChunkName: "manager" */ "../page/manager.vue"),
            },
            {
              path: "/log",
              component: () =>
                import(/* webpackChunkName: "log" */ "../page/log.vue"),
            },
          ],
        },
      ],
      // beforeEnter(to,from,next) {

      // }
    },
  ],
});

export default router;
